// router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import { useGlobalStore } from '@/store/store';
import { auth } from '@/firebase';
import { computed } from 'vue';
import { useDetectMobileDevice } from '@/composables/useDetectMobileDevice';
import { User } from 'firebase/auth';
import { storeToRefs } from 'pinia';
import { useTracking } from '@/composables/useTracking';

import Widget from '@/components/Widget.vue'


const detectMobileDevice = () => {
  const mediaQueryMatch = matchMedia('(hover: none), (pointer: coarse)').matches;
  const touchEventSupported = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
  const userAgentMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	const isTouchLaptop = /Windows NT/.test(navigator.userAgent) && touchEventSupported;

  if (isTouchLaptop) {
    return false;
  }

  return userAgentMatch || (mediaQueryMatch && touchEventSupported);
};

const { manageTracking }= useTracking()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // Public Routes (No specific layout)
    {
      path: '/entry',
      name: 'ChooseScreenComponent',
      component: () => detectMobileDevice()
        ? import('@/components/Login/ChooseScreenComponent.vue')
        : import('@/components/Login/ChooseScreenDesktopComponent.vue'),
    },

    {
      path: '/login',
      name: 'Login',
      component: () => detectMobileDevice()
        ? import('@/components/Login/LoginScreenComponent.vue')
        : import('@/components/Login/LoginScreenDesktopComponent.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => detectMobileDevice()
        ? import('@/components/Login/RegisterComponent.vue')
        : import('@/components/Login/RegisterDesktopComponent.vue'),
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: () => detectMobileDevice()
        ? import('@/components/Login/ForgotPassword.vue')
        : import('@/components/Login/ForgotPasswordDesktop.vue'),
    },
    {
      path: '/verify-email',
      name: 'EmailVerification',
      component: () => import('@/components/Login/EmailVerification.vue'),
    },
    {
      path: '/auth-action',
      name: 'AuthAction',
      component: () => import('@/components/Login/AuthAction.vue'),
    },
    {
      path: '/profile-analysis',
      name: 'ProfileAnalysis',
      component: () => detectMobileDevice()
        ? import('@/components/Login/ProfileAnalysis.vue')
        : import('@/components/Login/ProfileAnalysisDesktop.vue'),
      meta: { requiresAuth: false },
    },
    // Routes that use HeaderFooterLayout
		// {
    //   path: '/match',
    //   name: 'Match',
    //   component: () => import('@/components/ProfilePages/Match/MatchListDesktop.vue'),
    //   meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    // },
    {
      path: '/',
      name: 'Jobs',
      component: () => detectMobileDevice()
        ? import('@/components/ProfilePages/Match/VacanciesList.vue')
        : import('@/components/ProfilePages/Match/VacanciesListDesktop.vue'),
      meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    },
		{
      path: '/jobs/:id',
      name: 'JobDetails',
      component: () => detectMobileDevice()
			? import('@/components/ProfilePages/Match/VacanciesList.vue')
			: import('@/components/Jobs/JobDesktopDetails.vue'),
      meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    },
		{
      path: '/jobs',
      name: 'JobDetailsNoId',
      component: () => detectMobileDevice()
			? import('@/components/ProfilePages/Match/VacanciesList.vue')
			: import('@/components/Jobs/JobDesktopDetails.vue'),
      meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    },
    {
      path: '/cv',
      name: 'PreviewCV',
      component: () => detectMobileDevice()
        ? import('@/components/Resume/previewCV.vue')
        : import('@/components/Resume/previewCVDesktop.vue'),
      meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    },
		{
      path: '/cv-builder',
      name: 'PreviewCVBuilder',
      component: () => detectMobileDevice()
        ? import('@/components/Resume/previewCV.vue')
        : import('@/components/Resume/previewCVDesktop.vue'),
      meta: { requiresAuth: false, layout: 'HeaderFooterLayout' },
    },
    // {
    //   path: '/offers',
    //   name: 'Offers',
    //   component: () => import('@/components/ProfilePages/Offer/OfferPage.vue'),
    //   meta: { requiresAuth: true, layout: 'HeaderFooterLayout' },
    // },
    {
      path: '/bookmarks',
      name: 'Bookmarks',
      component: () => detectMobileDevice()
      ? import('@/components/ProfilePages/Bookmarks/BookmarksMobile.vue')
      : import('@/components/ProfilePages/Bookmarks/Bookmarks.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout' },
    },
    // Edit screens
    {
      path: '/ambition',
      name: 'AmbitionQuestionnair',
      component: () => import('@/components/ProfilePages/Ambition/AmbitionQuestionnair.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/passion',
      name: 'Passion',
      component: () => import('@/components/ProfilePages/Passion/PassionEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/summary',
      name: 'Summary',
      component: () => import('@/components/ProfilePages/Summary/SummaryEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/skills',
      name: 'Skills',
      component: () => import('@/components/ProfilePages/skill/Skills.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/skill/:id',
      name: 'SkillEdit',
      component: () => import('@/components/ProfilePages/skill/SkillEdit.vue'),
      props: true,
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/improve',
      name: 'Improve',
      component: () => import('@/components/ProfilePages/Improve/ImprovePage.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/improve-edit',
      name: 'ImproveEdit',
      component: () => import('@/components/ProfilePages/Improve/ImproveEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/software-improve',
      name: 'SoftwareImprove',
      component: () => import('@/components/ProfilePages/Improve/SoftwareImprovePage.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/personal',
      name: 'Personal',
      component: () => import('@/components/ProfilePages/Personal/PersonalEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/experiences',
      name: 'Experiences',
      component: () => import('@/components/ProfilePages/Experiences/ExperienceEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/education',
      name: 'Education',
      component: () => import('@/components/ProfilePages/Education/EducationEdit.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/software',
      name: 'SoftwareEdit',
      component: () => import('@/components/ProfilePages/Software/SoftwarePage.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
    {
      path: '/values',
      name: 'Values',
      component: () => import('@/components/ProfilePages/Ambition/Values.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true },
    },
		{
			path: '/edit-experience',
			component: () => import('@/components/ProfilePages/Experiences/SingleExperienceEdit.vue'),
			name: 'SingleEditExperience',
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
		},
		{
			path: '/toggle-experience',
			component: () => import('@/components/ProfilePages/Experiences/ToggleExperience.vue'),
			name: 'ToggleExperience',
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
		},
		{
			path: '/edit-education',
			component: () => import('@/components/ProfilePages/Education/SingleEducationEdit.vue'),
			name: 'SingleEducationEdit',
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
		},
		{
			path: '/toggle-education',
			component: () => import('@/components/ProfilePages/Education/ToggleEducation.vue'),
			name: 'ToggleEducation',
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
		},
    // Info Routes
    {
      path: '/info',
      component: () => import('@/components/ProfilePages/Info/InfoComponent.vue'),
      meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
      children: [
        {
          path: 'skills',
          component: () => import('@/components/ProfilePages/Info/Skills.vue'),
          name: 'InfoSkills',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
        {
          path: 'improve',
          component: () => import('@/components/ProfilePages/Info/Improve.vue'),
          name: 'InfoImprove',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
        {
          path: 'ambitions',
          component: () => import('@/components/ProfilePages/Info/Ambitions.vue'),
          name: 'InfoAmbitions',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
        {
          path: 'values',
          component: () => import('@/components/ProfilePages/Info/Values.vue'),
          name: 'InfoValues',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
        {
          path: 'experiences',
          component: () => import('@/components/ProfilePages/Info/Experiences.vue'),
          name: 'InfoExperiences',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
        {
          path: 'software',
          component: () => import('@/components/ProfilePages/Info/Software.vue'),
          name: 'InfoSoftware',
          meta: { requiresAuth: true, layout: 'HeaderFooterLayout', mobileOnly: true},
        },
      ],
    },
    // Other Routes
    {
      path: '/not-supported',
      name: 'NotSupported',
      component: () => import('@/components/NotSupported.vue'),
    },
    {
      path: '/widget',
      name: 'Widget',
      component: () => import('@/components/Widget.vue'),
      meta: { requiresAuth: false },
    },
		{
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/components/NotFound.vue'),
    },
		{
			path: '/validation/:token?',
			name: 'SkillValidation',
			component: () => import('@/components/Validations/Request/SkillValidation.vue'),
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout'},
			beforeEnter: (to, from, next) => {
				if (to.params?.token && to.params.token.includes('+v=')) {
					next({ name: 'SkillValidationReview', params: { token: to.params.token } });
				} else {
					next();
				}
			}
		},
		{
			path: '/review-validation/:token',
			name: 'SkillValidationReview',
			component: () => import('@/components/Validations/Reviews/SkillValidationReview.vue'),
			meta: { requiresAuth: true, layout: 'HeaderFooterLayout'},
			beforeEnter: (to, from, next) => {
				if (!to.params.token.includes('+v=')) {
					next({ name: 'SkillValidation', params: { token: to.params.token } });
				} else {
					next();
				}
			}
		}
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// Authentication Guard and Mobile-Only Route Guard
router.beforeEach((to, from, next) => {
  const { isMobile } = useDetectMobileDevice()
	const { isOverlayVisible, showMatchOveraly } = storeToRefs(useGlobalStore())
  
  if (isOverlayVisible.value) {
		isOverlayVisible.value = false;
  }
	
	if(to.name === 'PreviewCV' && from.name === 'ProfileAnalysis' && isMobile.value) {
		showMatchOveraly.value = true
	}

  auth.onAuthStateChanged(async (currentUser: User | null) => {
		// Authentication Check
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (currentUser) {

        // Mobile-Only Routes Check
        const isMobileOnly = to.matched.some((record) => record.meta.mobileOnly);
        if (isMobileOnly && !isMobile.value) {
          console.log('Desktop access to mobile-only route detected, redirecting to /cv...');
          return next({ name: 'PreviewCV' }); // Redirect to '/cv'
        } else {
          return next(); // Authenticated and allowed
        }
      } else {
        console.log('No current user, redirecting to login...');
				if(['SkillValidation', 'SkillValidationReview'].includes(to.name as string)) {
					return next(`/login?next=${to.fullPath}`)
				} else {
					return next();
				}

      }
    } else {
      // Mobile-Only Routes Check for Non-Authenticated Routes
      const isMobileOnly = to.matched.some((record) => record.meta.mobileOnly);

			if (currentUser) {
				if(to.name === 'PreviewCVBuilder') {
					return next({ name: 'PreviewCV' });
				}
			} else {

				if(to.name === 'JobDetails' || to.name === 'JobDetailsNoId') {
					manageTracking(to.params.id)
				}
			}

			if (isMobileOnly && !isMobile.value) {
        console.log('Desktop access to mobile-only route detected, redirecting to /cv...');
        return next({ name: 'PreviewCV' }); // Redirect to '/cv'
      } else {
        return next(); // No auth required and not mobile-only, proceed
      }
    }
  });
});

export default router;
