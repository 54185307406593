// axios
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { auth } from './firebase'; // Import your Firebase auth instance
import { transformKeyToSnakeCase, transformKeyToCamelCase } from '@/scripts/utils';

// Authenticated Axios Client
const apiClient = axios.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL}/api`,
  withCredentials: true,
});

// Public Axios Client
const publicApiClient = axios.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL}/api`,
  withCredentials: true, // No credentials for public client
});

// Add request interceptors
const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  // Skip transformation for FormData objects
  if (!(config.data instanceof FormData)) {
    config.data = transformKeyToSnakeCase(config.data);
  }
  return config;
};

apiClient.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('User not authenticated; proceeding without Authorization header.');
    }
    return requestInterceptor(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

publicApiClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

// Add response interceptors
const responseInterceptor = (response: AxiosResponse) => {
    // Skip transformation for binary/blob responses
    if (response.config.responseType === 'blob') {
        return response;
    }
    response.data = transformKeyToCamelCase(response.data);
    return response;
};

apiClient.interceptors.response.use(responseInterceptor, (error) => Promise.reject(error));
publicApiClient.interceptors.response.use(responseInterceptor, (error) => Promise.reject(error));

// Export both clients
export { apiClient, publicApiClient }; // Named exports
export default apiClient; // Default export
