<template>
  <div class="slider-container">
    <div class="main-level-bar">
      <div :class="levelClass" class="level-bar"></div>
      <div :style="thumbStyle" v-if="showBullets" class="thumb"></div>
      <!-- <span>{{ value }}</span> -->
      <input 
				:disabled="readonly"
				:readonly="readonly"
        type="range" 
        min="0" 
        max="9" 
        :value="value" 
        @input="updateValue"
        class="slider"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true
    },
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		showBullets: {
			type: Boolean,
			default: true
		}
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    levelClass() {
      return `level-${this.value}`;
    },
    thumbStyle() {
      return {
        left: `calc(${(this.value / 9) * 100}% - 2px)`,
      };
    }
  },
  methods: {
    updateValue(event) {
			if(this.readonly) return
      this.value = Number(event.target.value);
    }
  }
};
</script>

<style scoped>
.slider-container {
  width: 100%;
  margin-bottom: 10px;
}

.main-level-bar {
  width: 100%;
  height: 10px;
  border-radius: 2px;
  position: relative;
  /* overflow: hidden; */
  background: #E5E8ED; /* Light gray for remaining part */
}

.main-level-bar span {
  position: absolute;
  right: 10px;
  top: -20px;
}

.level-bar {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.level-9 {
  width: 100%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 12.5%, #F8E076 25%, #B8FFE4 37.5%, #86F1CB 50%, #45B68E 62.5%, #38BBC5 75%, #008DA0 87.5%, #002060 100%);
}

.level-8 {
  width: 88.88%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 14%, #F8E076 28%, #B8FFE4 42%, #86F1CB 56%, #45B68E 70%, #38BBC5 84%, #008DA0 100%);
}

.level-7 {
  width: 77.77%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 16%, #F8E076 32%, #B8FFE4 48%, #86F1CB 64%, #45B68E 80%, #38BBC5 100%);
}

.level-6 {
  width: 66.66%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 20%, #F8E076 40%, #B8FFE4 60%, #86F1CB 80%, #45B68E 100%);
}

.level-5 {
  width: 55.55%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 25%, #F8E076 50%, #B8FFE4 75%, #86F1CB 100%);
}

.level-4 {
  width: 44.44%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 33%, #F8E076 67%, #B8FFE4 100%);
}

.level-3 {
  width: 33.33%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 50%, #F8E076 100%);
}

.level-2 {
  width: 22.22%;
  background: linear-gradient(90deg, #F3ECD2 0%, #F8E9B3 100%);
}

.level-1 {
  width: 11.11%;
  background: #F3ECD2;
}

.level-0 {
  width: 0%;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Make the slider track completely transparent */
  cursor: pointer;
}

.thumb {
  position: absolute;
  top: -2.5px; /* Center the thumb vertically */
  width: 15px;
  height: 15px;
  background: white;
  /* border: 1px solid black; */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Adjust shadow as needed */
  border-radius: 50%;
  pointer-events: none; /* Make the thumb non-interactive */
  z-index: 1000;
}

</style>
