<template>
    <div class="menu-overlay">
  <div class="main-container-profile">

    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
      <span>Downloading CV...</span>
    </div>

    <div class="close" @click="goBack" :style="{ backgroundImage: 'url(' + CrossIcon + ')' }"></div>
    <div class="profile-info">
      <ProfilePicture class="profile-picture" />
      <div class="info-container">
        <div class="info-text">
          <span v-if="state.personalInfo.fullName" class="name">
            {{ state.personalInfo.fullName }}
          </span>
          <span class="info-email">
            {{ state.personalInfo.email }}
          </span>
        </div>
        <div v-if="isAuthenticated" @click="EditPersonalInfo">
          <img src="@/assets/arrowRight.svg" alt="arrow right" class="arrow-icon" />
        </div>
      </div>
    </div>

    <ul class="resume-actions">
      <li v-if="!['/cv-builder'].includes($route.path)" v-for="action in filterdActions" :key="action.text" class="resume-action" @click="action.handler">
        <div :class="['vector', action.iconClass]"></div>
        <span>{{ action.text }}</span>
      </li>
			<li v-else v-for="act in actionsBuilder" :key="act.text" class="resume-action" @click="act.handler">
        <div :class="['vector', act.iconClass]"></div>
        <span>{{ act.text }}</span>
      </li>
    </ul>

    <!-- Add menu footer -->
    <div class="menu-footer">
      Copyright © 2025 CareerSwitch B.V. | All rights reserved |
      <a href="https://www.lionhead.works/terms-and-conditions" target="_blank" rel="noopener noreferrer" class="menu-footer-link">Terms & Conditions</a>
      <span class="separator">|</span>
      <a href="https://www.lionhead.works/privacy-policy" target="_blank" rel="noopener noreferrer" class="menu-footer-link">Privacy Policy</a>
    </div>
  </div>

  <VideoPopup
      :videoUrl="'https://d29dp1chfnlx7y.cloudfront.net/mov/LH_Intro.mp4'"
      :visible="isVideoPopupVisible"
      @close="isVideoPopupVisible = false"
    />
</div>

</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import ProfilePicture from '@/components/ProfilePages/ProfilePicture.vue';
import { ref, computed } from 'vue';
import apiClient from '@/axios';
import CrossIcon from '@/assets/cross.svg';
import { auth } from "@/firebase"
import { useBlurCv } from '../../composables/useBlurCv';
import { useGlobalStore } from '@/store/store';
import { usePopup } from '@/composables/usePopup';
import { useNotification } from '@/composables/useNotification';
import VideoPopup from '@/components/CustomComponents/VideoPopup.vue';
import { storeToRefs } from 'pinia';

export default {
  name: 'MenuPage',
  components: {
    ProfilePicture,
    VideoPopup,
  },
  setup(props, { emit }) {
    const router = useRouter();
		const route = useRoute();
    const { saveState } = useGlobalStore();
    const loading = ref(false);
		const { toggleBlur } = useBlurCv()
		const { logout, downloadCV } = useGlobalStore()
		const { openPopup } = usePopup()

		const { addNotification } = useNotification();
		const { activeValidation } = storeToRefs(useGlobalStore())

    const isVideoPopupVisible = ref(false);

    const openVideoPopup = () => {
      console.log('openVideoPopup');
      isVideoPopupVisible.value = true;
    };

    const goBack = () => {
      emit('closeMenu');
    };

    const EditPersonalInfo = () => {
      emit('editProfile');
    };

    const shareCV = async () => {
			if(route.path === '/cv-builder') {
				openPopupAndCloseMenu();
				return
			}
      if (navigator.share) {
        try {
          await navigator.share({
            // title: 'Check out Lionhead Works',
            text: `Hey there! 🚀

I just came across this new platform – Lionhead Works! 🌟

It’s perfect for engineers looking for exciting opportunities.  
Here’s why you should check it out:

- Discover tailored job matches across Europe.  
- Build a standout CV and showcase your skills.  
- Stay anonymous while connecting with top employers.  

Take a look and see for yourself!  

🔗 [Lionhead Works](www.lionhead.works)  

Let me know what you think! 😊`,
// url: window.location.href,
          });
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
				addNotification({text: 'Sharing not supported on this browser.', type: 'error'})
      }
    };

    const toggleBlurLocal = () => {
			if(route.path === '/cv-builder') {
				openPopupAndCloseMenu();
				return
			}
      toggleBlur()
      emit('closeMenu');
    };
    
    const saveProfile = async () => {
			if(route.path === '/cv-builder') {
				openPopupAndCloseMenu();
				return
			}
      saveState();
			addNotification({text: 'Profile saved!', type:'success'})
    };

    const handleFAQ = () => {
      window.open('https://lionhead.works/faq-lionhead', '_blank').focus();
    };

    const logoutFn = async () => {
      await logout();
      router.push({ name: 'Login' });
    };

    const login = () => {
      router.push({ name: 'Login' });
    };

		const openPopupAndCloseMenu = () => {
			openPopup()
			emit('closeMenu');
		}

    const downloadCVFn = async () => {
			if(route.path === '/cv-builder') {
				openPopup();
				return;
			}
			loading.value = true;
			try {
				const response = await downloadCV();
				
				// Verify we have valid blob data
				if (!(response instanceof Blob)) {
					throw new Error('Invalid response format');
				}
				
				// Create blob URL
				const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
				
				// Create and trigger download
				const link = document.createElement('a');
				link.href = url;
				// link.download = `${personalInfo.value?.lastName}_${personalInfo.value?.firstName}_Resume.pdf`;
				link.download = 'Resume.pdf'; 
				document.body.appendChild(link);
				link.click();
				
				// Cleanup
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
				
				// Optional: Navigate to preview
				router.push({ name: 'PreviewCV' });
			} catch (error) {
				console.error('Error downloading CV:', error);
				addNotification({text: 'Failed to download CV', type: 'error'});
			} finally {
				loading.value = false;
			}
		};


    const isAuthenticated = computed(() => auth.currentUser !== null)

    const deleteUserData = async () => {
      // Show a confirmation dialog to the user
      const isConfirmed = confirm('Are you sure you want to delete your profile data? This action cannot be undone.');

      // Proceed only if the user confirms
      if (isConfirmed) {
        try {
          // Make a request to the backend to delete data from MongoDB
          const response = await apiClient.delete('/delete_user_data');
          
          if (response.status === 200) {
            await logout();

          } else {
            throw new Error('Failed to delete user data.');
          }
        } catch (error) {
          console.error('Error deleting user data:', error);

					addNotification({text: 'Failed to delete user data.', type:'error'})
        }
      } else {
        console.log('User canceled the delete action');
      }
    };

    const allowedFromUnauthenticated = ref([
      'faq',
      'video',
      'log',
			'save'
    ])

		const reviewSkill = () => {
			if(activeValidation.value) {
				router.push(`/validation/${activeValidation.value}`)
			} else {
				router.push({name: 'SkillValidation'})
			}
			emit('closeMenu')
		}

    const actions = ref([
      { text: 'Make resume anonymous', value: 'anonym' ,iconClass: 'vector-1', handler: toggleBlurLocal },
      { text: 'Download CV', value: 'download' ,iconClass: 'vector-2', handler: downloadCVFn },
			{ text: 'Review Skills', iconClass: 'vector-9', handler: reviewSkill },
      { text: 'Share CV', value: 'share' ,iconClass: 'vector-3', handler: shareCV },
      { text: 'Save profile', value: 'save' ,iconClass: 'vector-4', handler: isAuthenticated.value ? saveProfile : openPopupAndCloseMenu },
      { text: 'FAQ', value: 'faq' ,iconClass: 'vector-5', handler: handleFAQ },
      { text: 'Explainer video', value: 'video', iconClass: 'vector-8', handler: openVideoPopup },
      { text: isAuthenticated.value ? 'Logout' : 'Login', value: 'log' ,iconClass: 'vector-6', handler: isAuthenticated.value ? login : logoutFn },
      { text: 'Delete profile', value: 'del' ,iconClass: 'vector-7', handler: deleteUserData },
    ]);

    const filterdActions = computed(() => {
      return actions.value.filter((action) => {
        return isAuthenticated.value || allowedFromUnauthenticated.value.includes(action.value)
      })
    })

		const actionsBuilder = [
			{ text: 'Make resume anonymous', frameClass: 'frame', iconClass: 'lock-icon', handler: toggleBlurLocal },
			{ text: 'Download CV', frameClass: 'frame-download', iconClass: 'download-icon', handler: downloadCVFn },
			{ text: 'Share CV', frameClass: 'frame-share', iconClass: 'share-icon', handler: shareCV, separator: true },
			{ text: 'Save profile', frameClass: 'frame-save', iconClass: 'save-profile-icon', handler: saveProfile },
		]

    return {
      actions,
			actionsBuilder,
      state: useGlobalStore().$state,
      goBack,
      EditPersonalInfo,
      CrossIcon,
      loading,
      filterdActions,
      isAuthenticated,
      isVideoPopupVisible,
    };
  },
};
</script>



<style scoped>

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255); /* You can adjust the background color and opacity */
  z-index: 5000; /* Ensure the menu is above all other elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* If the menu content exceeds the screen height */
}
.main-container-profile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.main-container-profile {
  max-height: calc(100vh - 125px); /* 45px header + 60px footer */
  overflow: hidden;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-size: cover;
  cursor: pointer;
}

.profile-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.profile-picture {
  height: auto;
  width: 100px;
  margin-right: 10px;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 160px;
  width: 100%;
}

.info-text {
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: space-between;
}

.name {
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 21.784px;
  text-align: left;
  white-space: nowrap;
}

.info-email {
  color: #7d89a5;
  font-family: Inter, var(--default-font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.523px;
  text-align: left;
  white-space: nowrap;
}

.arrow-icon {
  width: 18px; /* Adjust the size as needed */
  height: 36px; /* Adjust the size as needed */
  cursor: pointer;
}

.vector {
  background-size: 100% 100%;
}

.resume-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  list-style: none;
  padding: 0;
  margin-top: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.resume-action {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
	cursor: pointer;
}

.arrow {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  background: url('@/assets/arrowRight.svg') no-repeat center;
}

.vector-1 {
  width: 25px;
  height: 25px;
  background: url('@/assets/lock.svg') no-repeat center;
}

.vector-2 {
  width: 25px;
  height: 25px;
  background: url('@/assets/download.svg') no-repeat center;
}

.vector-3 {
  width: 25px;
  height: 25px;
  background: url('@/assets/share.svg') no-repeat center;
}

.vector-4 {
  width: 25px;
  height: 25px;
  background: url('@/assets/saveProfile.svg') no-repeat center;
}

.vector-5 {
  width: 25px;
  height: 25px;
  background: url('@/assets/question-mark.svg') no-repeat center;
}

.vector-6 {
  width: 25px;
  height: 25px;
  background: url('@/assets/logout.svg') no-repeat center;
}

.vector-7 {	
  width: 25px;
  height: 25px;
  background: url('@/assets/delete_forever.svg') no-repeat center;
}

.vector-8 {
  width: 25px;
  height: 25px;
  background: url('@/assets/movie.svg') no-repeat center;
}

.vector-9 { 
	width: 25px;
	height: 25px;
	background: url('@/assets/review.svg') no-repeat center;
	background-size: contain;
}

.resume-action span {
  margin-left: 10px;
}


.loader-container {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  position: absolute; /* Ensure loader overlays the content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 6000; /* Ensure it appears above other elements */
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.menu-footer {
  margin-top: auto;
  padding: 20px 0px;
  text-align: center;
  border-top: 1px solid #edf4ff;
}

.menu-footer-link {
  color: #056cf2;
  text-decoration: none;
  font-size: 12px;
}

.separator {
  margin: 0 10px;
  color: #333;
}
</style>
