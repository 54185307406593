<template>
  <div id="app">
    <!-- Global Measurement Container outside of any transformed elements -->

    <!-- Conditionally render layout if defined -->
    <template v-if="hasLayout">
      <component :is="layoutComponent">
        <router-view />
      </component>
    </template>

    <!-- Render without layout if no layout is defined -->
    <template v-else>
      <router-view />
    </template>

    <VideoPopup 
      :videoUrl="videoUrl" 
      :visible="isVideoPopupVisible" 
      @close="closeVideoPopup" 
    />
    
    <ModalPopup />
		<Cropper />
		<CustomNotification />
		<MatchLockOverlay />
		<ImproveProfileNotification />


    <!-- Add copyright footer -->
    <div v-if="!isMobileDevice" class="copyright-footer">
      <div class="copyright-content">
        Copyright © 2025 CareerSwitch B.V. | All rights reserved |
        <a href="https://www.lionhead.works/terms-and-conditions" target="_blank" rel="noopener noreferrer" class="footer-link">Terms & Conditions</a> |
        <a href="https://www.lionhead.works/privacy-policy" target="_blank" rel="noopener noreferrer" class="footer-link">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, watch, ref, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import HeaderFooterLayout from '@/components/ProfilePages/HeaderFooterLayout.vue';
import HeaderFooterLayoutMobile from '@/components/ProfilePages/HeaderFooterLayoutMobile.vue';
import ModalPopup from './components/CustomComponents/ModalPopup.vue';
import { usePopup } from './composables/usePopup';

import { auth } from '@/firebase'
import { useGlobalStore } from './store/store';
import Cropper from './components/CustomComponents/Cropper.vue';
import CustomNotification from './components/CustomComponents/CustomNotification.vue';
import GradientBackground from './components/CustomComponents/GradientBackground.vue';
import { useGetIpAddress } from './composables/useGetIpAddress';
import { useCookie } from './composables/useCookie';
import { useFingerprint } from './composables/useFingerprint';
import { useNotification } from './composables/useNotification';
import MatchLockOverlay from './components/ProfilePages/Match/MatchLockOverlay.vue';
import ImproveProfileNotification from './components/CustomComponents/ImproveProfileNotification.vue';
import VideoPopup from './components/CustomComponents/VideoPopup.vue';

defineComponent({
  ModalPopup,
	Cropper,
	CustomNotification,
	GradientBackground
})

// === Constants ===
const NOTIFICATION_DELAYS = {
  FIRST: 5* 60 * 1000,    // 5 seconds
  SECOND: 8 * 60 * 1000  // 8 minutes
};

const EXCLUDED_PATHS = [
  '/register',
  '/login',
  '/profile-analysis',
  '/widget',
  '/choose-screen',
  '/forgot-password',
  '/email-verification',
  '/auth/action',
  '/preview-cv-builder',
];

// === Store & Route Setup ===
const { isMobileDevice } = storeToRefs(useGlobalStore());
const { setupEventListener, cleanupEventListener } = useGlobalStore();

const route = useRoute();
const router = useRouter();

// === Composables ===
const { setTexts, setButtonFn, closePopup, registerPopup, setClasses, isButtonShown } = usePopup();
const { getCookie, updateCookie } = useCookie();
const { generateFingerprint } = useFingerprint();
const { addNotification } = useNotification();

// === Helper Methods ===
const sendNotification = () => {
  if (!auth.currentUser) {  // Double-check user is still logged out
    addNotification({
      text: 'To save vacancies and save your profile, create your free account now.',
      type: 'info',
      buttonText: 'Create account',
      action: () => router.push({ name: 'Register' })
    }, false);
  }
};

// Add this with other refs
const notificationTimeouts = ref([]);

function setupNotifications(path) {
  // Clear existing timeouts
  notificationTimeouts.value.forEach(clearTimeout);
  notificationTimeouts.value = [];

  if (EXCLUDED_PATHS.includes(path)) {
    console.log('Notifications skipped - excluded path:', path);
    return;
  }

  console.log('Setting up notifications for path:', path);
  
  notificationTimeouts.value.push(
    setTimeout(() => sendNotification(), NOTIFICATION_DELAYS.FIRST),
    setTimeout(() => sendNotification(), NOTIFICATION_DELAYS.SECOND)
  );
}

// === Watchers ===
watch(
  () => route.path,
  (newPath, oldPath) => {
    if (!auth.currentUser) {
      setupNotifications(newPath);
    }
  },
  { immediate: true }
);

// Add cleanup on component unmount
onUnmounted(() => {
	// cleanupEventListener()
  notificationTimeouts.value.forEach(clearTimeout);
});

// === Lifecycle Hooks ===
onMounted(async () => {
	// setupEventListener()
  localStorage.removeItem('authToken');

  // Handle user tracking
  const userTracking = getCookie('userTracking') || {};
  if (!userTracking.fingerprint) {
    const fingerprint = await generateFingerprint();
    updateCookie('userTracking', 'fingerprint', fingerprint);
  }
  if (userTracking.maxLimitReached === undefined) {
    updateCookie('userTracking', 'maxLimitReached', false);
  }

  // // Initial popup for non-authenticated users on allowed paths
  // if (!auth.currentUser && !EXCLUDED_PATHS.includes(route.path)) {
  //   setTexts({
  //     subtitleText: 'Unlock all opportunities',
  //     popupText: `Your profile gives you access to permanent opportunities. 
  //       Log in for free to access all Lionhead services and matching jobs.`,
  //     textButton: 'Create free account'
  //   });
  //   setClasses({ buttonClasses: 'w-100' });
  //   registerPopup.value = true;
  //   isButtonShown.value = true;
  //   setButtonFn(() => {
  //     router.push({ name: 'Register' });
  //     closePopup();
  //   });
  // }

});

// === Computed Properties ===
const hasLayout = computed(() => !!route.meta.layout);
const layoutComponent = computed(() => {
  const layout = route.meta.layout;
  if (layout === 'HeaderFooterLayout') {
    return isMobileDevice.value ? HeaderFooterLayoutMobile : HeaderFooterLayout;
  }
  console.warn(`Unknown layout "${layout}" specified in route meta.`);
  return null;
});

const store = useGlobalStore()
const { isVideoPopupVisible, videoUrl } = storeToRefs(store)
const { closeVideoPopup } = store
</script>

<style>
/* ... existing styles ... */

.copyright-footer {

  bottom: 0;
  left: 0;
  right: 0;
  background-color: #EDF4FF;
  padding: 8px 0;
  text-align: center;
  font-size: 12px;
  z-index: 999;
}


.copyright-content {
  color: #333;
  padding: 0 15px;
}

.footer-link {
  color: #056cf2;
  text-decoration: none;
  pointer-events: auto;
}

.footer-link:hover {
  text-decoration: underline;
}
</style>
